import React from 'react'
import { motion } from "framer-motion"
import styles from './Transfer.module.scss'
import image from '../../image/Transfer/car.png'
import wp from '../../image/Transfer/wp.svg'
import tg from '../../image/Transfer/tg.svg'

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}

const Transfer = () => {

    return (
        <motion.section 
            className={styles.main}
            id='transfer'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box} variants={boxAnimation}>
                <div className={styles.box_text}>
                    <h2 className={styles.title}>Ориентировочная стоимость трансфера</h2>
                    <p className={styles.text}>Анапа - Морская 51 - <span>2000₽</span></p>
                    <p className={styles.text}>Тонельная - Морская 51 -  <span>3000₽</span></p>
                    <p className={styles.text}>Новоросийск - Морская 51 -  <span>3500₽</span></p>
                    <p className={styles.text}>Тамань - Морская 51 -  <span>1800₽</span></p>
                    <a className={styles.link} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79166554701' aria-label='связаться вацап'>
                                <img src={wp} alt="Whatsapp"/>
                                <span>Написать в Whatsapp</span>
                            </a>
                            <a className={styles.link_telegram} target='_blank' rel='noopener noreferrer' href='https://t.me/+79166554701' aria-label='телеграмм'>
                                <img src={tg} alt="Whatsapp"/>
                                <span>Написать в telegram</span>
                            </a>
                </div>
                <img className={styles.image} src={image} alt='презентация'/>
            </motion.div>
        </motion.section>
    )
}

export default Transfer