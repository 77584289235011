import React from 'react'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'
import image from '../../image/AboutUs/img.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const AboutUs = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            id='AboutUs'             
            >
            <div className={styles.container}>
                <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>О гостевом доме <span>На&#160;Морской</span></h2>
                        <p className={styles.text}>&#8195;Гостевой дом "На Морской" расположен на закрытой территории площадью 1400 м2 в курортном поселке За Родину в Темрюкском районе Краснодарского края, в непосредственной близости от грязевого вулкана "ТИЗДАР", на первой линии у моря.</p>
                        <p className={styles.text}>&#8195;В гостевом доме представлены несколько категорий номеров: «Однокомнатный 2-х местный», «Однокомнатный 3-х местный», «Двухкомнатный 4-х местный» и «Двухкомнатный 5-ти местный». В номерах есть все необходимое для комфортного пребывания: одно и двух-спальные кровати, санузлы с душем, кондиционер, спутниковое ТВ, холодильник, бесплатный Wi-Fi и т.д. По желанию клиента номера могут быть оборудованы дополнительными спальными местами и детской кроваткой.</p>
                        <p className={styles.text}>&#8195;Территория гостевого дома хорошо благоустроена. Между клумбами с цветами, декоративными и плодовыми растениями уложена тротуарная плитка. Дома расположены  так, чтобы к полудню на балконах и в номерах была максимальная тень.</p>
                        <p className={styles.text}>&#8195;Нашим гостям мы предлагаем различные схемы питания: 3-х разовое; завтрак, обед и ужин по-отдельности; самостоятельное приготовление, для чего у нас оборудована кухня с 2-мя плитами, микроволновой печью и всей необходимой посудой.</p>
                        <p className={styles.text}>&#8195;Насладитесь уютной и спокойной атмосферой на прекрасном побережье Азовского моря вместе с гостевым домом "На Морской".</p>
                        <div className={styles.box_link}>
                            <a className={styles.link} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79166554701' aria-label='связаться вацап'>забронировать номер</a>
                        </div>
                    </motion.div>
            </div>
        </motion.section>
    )
}

export default AboutUs