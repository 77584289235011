import img_1 from '../image/InteriorDecoration/Territory/img_1.webp'
import img_2 from '../image/InteriorDecoration/Territory/img_2.webp'
import img_3 from '../image/InteriorDecoration/Territory/img_3.webp'
import img_4 from '../image/InteriorDecoration/Territory/img_4.webp'
import img_5 from '../image/InteriorDecoration/Territory/img_5.webp'
import img_6 from '../image/InteriorDecoration/Territory/img_6.webp'
import img_7 from '../image/InteriorDecoration/Territory/img_7.webp'
import img_8 from '../image/InteriorDecoration/Territory/img_8.webp'
import img_9 from '../image/InteriorDecoration/Territory/img_9.webp'
import img_10 from '../image/InteriorDecoration/Territory/img_10.webp'

import bea_1 from '../image/InteriorDecoration/Beaches/img_1.webp'
import bea_2 from '../image/InteriorDecoration/Beaches/img_2.webp'
import bea_3 from '../image/InteriorDecoration/Beaches/img_3.webp'
import bea_4 from '../image/InteriorDecoration/Beaches/img_4.webp'
import bea_5 from '../image/InteriorDecoration/Beaches/img_5.webp'
import bea_6 from '../image/InteriorDecoration/Beaches/img_6.webp'
import bea_7 from '../image/InteriorDecoration/Beaches/img_7.webp'
import bea_8 from '../image/InteriorDecoration/Beaches/img_8.webp'
import bea_9 from '../image/InteriorDecoration/Beaches/img_9.webp'
import bea_10 from '../image/InteriorDecoration/Beaches/img_10.webp'

import oil_1 from '../image/Loading/img_1.webp'
import oil_2 from '../image/Loading/img_2.webp'
import oil_3 from '../image/Loading/img_3.webp'
import oil_4 from '../image/Loading/img_4.webp'
import oil_5 from '../image/Loading/img_5.webp'
import oil_6 from '../image/Loading/img_6.webp'
import oil_7 from '../image/Loading/img_7.webp'
import oil_8 from '../image/Loading/img_8.webp'
import oil_9 from '../image/Loading/img_9.webp'

import dou_1 from '../image/Rooms/Double/img_1.webp'
import dou_2 from '../image/Rooms/Double/img_1_1.webp'
import dou_3 from '../image/Rooms/Double/img_1_2.webp'
import dou_4 from '../image/Rooms/Double/img_1_4.webp'

import tri_1 from '../image/Rooms/Triple/img_1.webp'
import tri_2 from '../image/Rooms/Triple/img_2.webp'
import tri_3 from '../image/Rooms/Triple/img_3.webp'
import tri_4 from '../image/Rooms/Triple/img_4.webp'
import tri_5 from '../image/Rooms/Triple/img_5.webp'
import tri_6 from '../image/Rooms/Triple/img_6.webp'

import fou_1 from '../image/Rooms/Four/img_1.webp'
import fou_2 from '../image/Rooms/Four/img_2.webp'
import fou_3 from '../image/Rooms/Four/img_3.webp'
import fou_4 from '../image/Rooms/Four/img_4.webp'
import fou_5 from '../image/Rooms/Four/img_5.webp'
import fou_6 from '../image/Rooms/Four/img_6.webp'
import fou_7 from '../image/Rooms/Four/img_7.webp'

export const arrAdvantages = [{text:'Бесплатная СТОЯНКА'}, {text:'Первая ЛИНИЯ'}, {text:'Система ВИДЕОНАБЛЮДЕНИЯ'}, {text:'Различные схемы ПИТАНИЯ'}, {text:'Бесплатный ИНТЕРНЕТ'}, {text:'Индивидуальный ПОДХОД'},];

export const arrObjects = [{image: oil_1, title: 'Долина лотосов'}, {image: oil_2, title: 'Вулкан Тиздар'}, {image: oil_3, title: 'Зоопарк Усадьба'}, {image: oil_4, title: 'Дом-музей Лермонтова'}, {image: oil_5, title: 'Аквапарк Морской риф'}, {image: oil_6, title: 'Завод Фанагория'}, {image: oil_7, title: 'Станица Атамань'}, {image: oil_8, title: 'Коса Тузла'}, {image: oil_9, title: 'Сыроварня Он Чиз'},];

export const arrTerritory = [{image: img_2, title: 'Гостевой дом На Морской'}, {image: img_1, title: 'Гостевой дом На Морской'}, {image: img_3, title: 'Гостевой дом На Морской'}, {image: img_4, title: 'Гостевой дом На Морской'}, {image: img_5, title: 'Гостевой дом На Морской'}, {image: img_6, title: 'Гостевой дом На Морской'}, {image: img_7, title: 'Гостевой дом На Морской'}, {image: img_8, title: 'Гостевой дом На Морской'}, {image: img_9, title: 'Гостевой дом На Морской'}, {image: img_10, title: 'Гостевой дом На Морской'},];

export const arrBeaches = [{image: bea_2, title: 'Гостевой дом На Морской'}, {image: bea_1, title: 'Гостевой дом На Морской'}, {image: bea_3, title: 'Гостевой дом На Морской'}, {image: bea_4, title: 'Гостевой дом На Морской'}, {image: bea_5, title: 'Гостевой дом На Морской'}, {image: bea_6, title: 'Гостевой дом На Морской'}, {image: bea_7, title: 'Гостевой дом На Морской'}, {image: bea_8, title: 'Гостевой дом На Морской'}, {image: bea_9, title: 'Гостевой дом На Морской'}, {image: bea_10, title: 'Гостевой дом На Морской'},];

export const arrDouble = [{image: dou_1, title: 'Гостевой дом На Морской'}, {image: dou_4, title: 'Гостевой дом На Морской'}, {image: dou_2, title: 'Гостевой дом На Морской'}, {image: dou_3, title: 'Гостевой дом На Морской'}, ];

export const arrTriple = [{image:  tri_1, title: 'Гостевой дом На Морской'}, {image:  tri_2, title: 'Гостевой дом На Морской'}, {image:  tri_3, title: 'Гостевой дом На Морской'}, {image:  tri_4, title: 'Гостевой дом На Морской'}, {image:  tri_5, title: 'Гостевой дом На Морской'}, {image:  tri_6, title: 'Гостевой дом На Морской'},];

export const arrFour = [{image:  fou_1, title: 'Гостевой дом На Морской'}, {image:  fou_2, title: 'Гостевой дом На Морской'}, {image:  fou_3, title: 'Гостевой дом На Морской'}, {image:  fou_4, title: 'Гостевой дом На Морской'}, {image:  fou_5, title: 'Гостевой дом На Морской'}, {image:  fou_6, title: 'Гостевой дом На Морской'}, {image:  fou_7, title: 'Гостевой дом На Морской'},];

