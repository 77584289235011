import React from 'react'
import header from './Header.module.scss'
import logo from '../../image/Header/logo.png'
import ButtonBox from '../ButtonBox/ButtonBox'
import yandex from '../../image/Header/orig_1.png'

const Header = ({isOpen}) => {

    return (
        <header className={header.header}>
        <div className={header.box}>
                <div className={header.info}>
                    <div className={header.box_logo}>
                        <a className={header.logo_link} href='initial'>
                            <img className={header.logo} src={logo} alt = 'логотип'/>
                        </a>
                        <div className={header.contacts}>
                            <a className={header.yandex_link} href='https://yandex.ru/maps/org/gostevoy_dom_na_morskoy/124894573713/reviews/?ll=37.104634%2C45.353110&z=16' target='_blank' rel='noopener noreferrer'>
                                <img className={header.yandex} src={yandex} alt = 'логотип'/>
                            </a>
                            <div className={header.box_social}>
                                <ButtonBox/>
                            </div>
                            <div className={header.box_phone}>
                                <a className={header.phone}  href="tel:+79166554701" target='_blank' rel='noopener noreferrer'>+7 916 <span>655-47-01</span></a>
                            </div>
                        </div>
                        <button className={header.button} type='button' onClick={isOpen} aria-label='Открыть меню'/>
                    </div>
                    <nav className={header.nav}>
                        <a className={header.link} href='#galereya'>О нас</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#rooms'>Номера</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#transfer'>Трансферы</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#territory'>Территория и окресности</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#okruzhenie'>Интересные места</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#kontakty'>Контакты</a>
                    </nav>
                </div>
        </div>
    </header>    
    );
};

export default Header