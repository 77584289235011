import React from 'react'
import styles from './Feedback.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import "./Feedback.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import img from '../../image/Feedback/img.webp'



const Feedback = () => {


    return (
        <section className={styles.main} id='Septic'>
            <div className={styles.box}>
                <h2 className={styles.title}>Отзывы <span>наших гостей</span></h2>
                    <div className={styles.slider}>
                        <Swiper
                                spaceBetween={10}
                                slidesPerView={1.3}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    type: 'fraction'
                                }}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 5,
                                    },

                                    768: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 5,
                                    },

                                    425: {
                                        slidesPerView: 1.1,
                                        spaceBetween: 5,
                                    },

                                    320: {
                                        slidesPerView: 1.05,
                                        spaceBetween: 5,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                                >
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Eva</p>
                                        <p className={styles.comment}>18.08.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Огромное Спасибо радушной хозяйке за тёплый приём!🤗 Комната комфортная и чистая со всеми удобствами! Ещё и питание по домашнему, всё свежее, вкусное👍 Всё в шаговой доступности и грязевой вылкан, и море и магазинчики👌В следующий раз приедем только сюда!!! 🙌🙌🙌</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Сергей Юрин</p>
                                        <p className={styles.comment}>30.08.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Были проездом в этом году. Что могу сказать? Отличное место, где можно отдохнуть. Отзывчивая хозяйка, приняла и заселила нас под утро, за что огромное спасибо. Чистые, комфортные, уютные номера, спокойная обстановка на территории и готовят очень вкусную еду. Так же очень близкое расположение от грязевого вулкана Тиздар, из за него и заехали. Все супер, очень рекомендую посетить. В следующем году обязательно заедем!</p>                              
                                    </div>
                                </div>  
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className={styles.cell}>
                                <div className={styles.item}>
                                    <p className={styles.subtitle}>Виктор "Fish"</p>
                                    <p className={styles.comment}>28.08.2023</p>
                                    <img className={styles.image} src={img} alt = 'звездочки'/>
                                    <p className={styles.comment}>Комментарий</p>
                                    <p className={styles.text}>Уютное, тихое место с очень хорошим персоналом! Можно заказать завтрак/ужин. Не далеко пара магазинов. До ближайшего сетевого магазина, банкомата и аптеки на машине 5 минут. Не далеко от моря и грязевого вулкана. С удовольствием приеду еще раз. Понравится тем, кто не любит суету.</p>                              
                                </div>
                            </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Алексей Орлов</p>
                                        <p className={styles.comment}>23.06.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Небольшие чистые номера со всем неоходимым. Готовить можно самим или есть возможность заказать на месте за доп плату. Есть места для парковки на территории на 6-8 машин. В сезон можно кушать вишню, черешню и шелковицу с деревьев. Дают скидку 50% для посещения грязевого вулкана.</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Марина</p>
                                        <p className={styles.comment}>27.08.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Отличное место, чтоб остановиться у вулкана Тиздар! Хозяйка даже предоставляет карточку на скидку 50% номера чистые, тел есть, и даже есть повар, которая может приготовить вам и завтрак и ужин, главное договориться чуть за ранее. Мы остались в восторге , даже есть парковка и детский батут!</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Ольга Ланцова</p>
                                        <p className={styles.comment}>16.09.2021</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Хорошие хозяева. Нам досталась просторная комната. Тишина, даже не привычно после городского шума. Когда увидела их маленькую собачку около калитки, думала что плохой сон обеспечен, тк такие по пол ночи лают, но я ошибалась, собака всю ночь спит. До моря рядом, до "грязи" рядом, с ребенком 5ти лет пешком ходили. Мы очень довольны остались</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Георгий Кузя</p>
                                        <p className={styles.comment}>22.07.2020</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Отдыхаем здесь много лет подряд. Всегда чисто, оооочннь вкусно, но при этом можно готовить и самим. Самые гостиприимные, радушные хозяева ( повидали многих в разных городах нашего юга) и вот уже 5й год возвращаемся к Руслане.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://yandex.ru/maps/org/gostevoy_dom_na_morskoy/124894573713/reviews/?ll=37.104634%2C45.353110&z=16' aria-label='отзывы'>отзывы на yandex</a>
                </div>
        </section>
    )
}

export default Feedback