import React, {useState, useCallback} from 'react'
import { motion } from "framer-motion"
import styles from './Rooms.module.scss'
import { arrDouble } from '../../utils/data'
import { arrTriple } from '../../utils/data'
import { arrFour } from '../../utils/data'
import img_1 from '../../image/Rooms/img_1.webp'
import img_1_1 from '../../image/Rooms/img_1_1.webp'
import img_1_2 from '../../image/Rooms/img_1_2.webp'
import img_1_3 from '../../image/Rooms/img_1_4.webp'
import img_2 from '../../image/Rooms/img_2.webp'
import img_2_1 from '../../image/Rooms/img_2_1.webp'
import img_2_2 from '../../image/Rooms/img_2_2.webp'
import img_3 from '../../image/Rooms/img_3.webp'
import img_3_1 from '../../image/Rooms/img_3_1.webp'
import img_3_2 from '../../image/Rooms/img_3_2.webp'
import img_3_3 from '../../image/Rooms/img_3_3.webp'
import price from '../../image/Rooms/money.png'
import parking from '../../image/Rooms/Icons/parking.svg'
import bed from '../../image/Rooms/Icons/кровать.svg'
import bed_1 from '../../image/Rooms/Icons/кровать_мал.svg'
import wc from '../../image/Rooms/Icons/wc.svg'
import shower from '../../image/Rooms/Icons/shower.svg'
import television from '../../image/Rooms/Icons/television.svg'
import refrigerator from '../../image/Rooms/Icons/refrigerator.svg'
import conditioner from '../../image/Rooms/Icons/conditioner.svg'
import dresser from '../../image/Rooms/Icons/dresser.svg'
import cupboard from '../../image/Rooms/Icons/cupboard.svg'
import table from '../../image/Rooms/Icons/table.svg'

const titleAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .8}
    } 
}


const Rooms = ({isImageOpen}) => {

    const [isImage, setIsImege] = useState(1)
    const [isBackground, setIsBackground] = useState(1)

    const doubleClick = useCallback(() => {
        isImageOpen(arrDouble)
    }, [isImageOpen])

    const tripleClick = useCallback(() => {
        isImageOpen(arrTriple)
    }, [isImageOpen])

    const fourClick = useCallback(() => {
        isImageOpen(arrFour)
    }, [isImageOpen])

    const handleBackgroundOne = useCallback(() => {
        setIsBackground(1)
        setIsImege(1)
    }, []);

    const handleBackgroundTwo = useCallback(() => {
        setIsBackground(2)
        setIsImege(2)
    }, []);

    const handleBackgroundThree = useCallback(() => {
        setIsBackground(3)
        setIsImege(3)
    }, []);

    const handleBackgroundFour = useCallback(() => {
        setIsBackground(4)
        setIsImege(4)
    }, []);


    return (
        <motion.section 
            className={styles.main}
            id='rooms'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.overlay}>
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Доступные номера</motion.h2>
                <motion.ul className={styles.list_link} variants={titleAnimation}>
                        <li className={`${isBackground === 1 ? styles.item_blue : styles.item}`} onClick={handleBackgroundOne}>2-местный
                        </li>
                        <li className={`${isBackground === 2 ? styles.item_blue : styles.item}`} onClick={handleBackgroundTwo}>3-местный</li>
                        <li className={`${isBackground === 3 ? styles.item_blue : styles.item}`} onClick={handleBackgroundThree}>4-местный</li>
                        <li className={`${isBackground === 4 ? styles.item_blue : styles.item}`} onClick={handleBackgroundFour}>5-местный</li>
                </motion.ul>
                <div className={`${isImage === 1 ? styles.room : styles.room_none}`}>
                    <ul className={styles.list_image} onClick={doubleClick}>
                        <li className={styles.item_1}>
                            <img className={styles.image} src={img_1} alt = 'номер'/>
                        </li>
                        <li className={styles.item_2}>
                            <img className={styles.image} src={img_1_1} alt = 'номер'/>
                        </li>
                        <li className={styles.item_3}>
                            <img className={styles.image} src={img_1_2} alt = 'номер'/>
                        </li>
                        <li className={styles.item_4}>
                            <img className={styles.image} src={img_1_3} alt = 'номер'/>
                        </li>
                    </ul>
                    <div className={styles.room_info}>
                        <h3 className={styles.room_title}>2-МЕСТНЫЙ НОМЕР (большая&#160;кровать)</h3>
                        <div className={styles.room_line}></div>
                        <div className={styles.box_price}>
                            <img className={styles.image_price} src={price} alt = 'деньги'/>
                            <p className={styles.room_price}>весна-осень от 1400₽ / лето&#160;от&#160;1800₽</p>
                        </div>
                        <div className={styles.box_button}>
                            <button className={styles.button} tupe='button' onClick={doubleClick}>смотреть фото</button>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79166554701' aria-label='связаться вацап'>забронировать</a>
                        </div>
                        <div className={styles.room_line}></div>
                        <ul className={styles.icon_list}>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={parking} alt = 'иконка'/>
                                <p className={styles.icon_text}>бесплатная парковка</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={bed} alt = 'иконка'/>
                                <p className={styles.icon_text}>двуспальная кровать</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={table} alt = 'иконка'/>
                                <p className={styles.icon_text}>стол и стулья</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={wc} alt = 'иконка'/>
                                <p className={styles.icon_text}>туалет</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={shower} alt = 'иконка'/>
                                <p className={styles.icon_text}>душ</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={conditioner} alt = 'иконка'/>
                                <p className={styles.icon_text}>кондиционер</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={television} alt = 'иконка'/>
                                <p className={styles.icon_text}>телевизор</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={refrigerator} alt = 'иконка'/>
                                <p className={styles.icon_text}>холодильник</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={dresser} alt = 'иконка'/>
                                <p className={styles.icon_text}>комод</p>
                            </li>
                        </ul>
                        <div className={styles.room_line}></div>
                        <p className={styles.room_text_bold}>расчётное время выезда 12:00 расчетное&#160;время&#160;заезда&#160;14:00</p>
                        <p className={styles.room_text}>По желанию наших гостей, номера дополнительно могут быть оборудованы спальными местами(300₽) и детской кроваткой(300₽).</p>
                        <p className={styles.room_text}>В целях пожарной безопасности, <span>категорически запрещается</span> использовать в номерах бытовые электроприборы (мультиварки, чайники, утюги и т.п.)!</p>
                    </div>
                </div>
                <div className={`${isImage === 2 ? styles.room : styles.room_none}`}>
                    <ul className={styles.list_image} onClick={tripleClick}>
                        <li className={styles.item_1}>
                            <img className={styles.image} src={img_2} alt = 'здание'/>
                        </li>
                        <li className={styles.item_2}>
                            <img className={styles.image} src={img_2_1} alt = 'здание'/>
                        </li>
                        <li className={styles.item_3}>
                            <img className={styles.image} src={img_2_2} alt = 'здание'/>
                        </li>
                        <li className={styles.item_4}>
                            <img className={styles.image} src={img_1_2} alt = 'здание'/>
                        </li>
                    </ul>
                    <div className={styles.room_info}>
                        <h3 className={styles.room_title}>3-МЕСТНЫЙ НОМЕР (есть&#160;с&#160;кухней)</h3>
                        <div className={styles.room_line}></div>
                        <div className={styles.box_price}>
                            <img className={styles.image_price} src={price} alt = 'деньги'/>
                            <p className={styles.room_price}>весна-осень от 1800₽ / лето&#160;от&#160;2500₽</p>
                        </div>
                        <div className={styles.box_button}>
                            <button className={styles.button} tupe='button' onClick={tripleClick}>смотреть фото</button>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79166554701' aria-label='связаться вацап'>забронировать</a>
                        </div>
                        <div className={styles.room_line}></div>
                        <ul className={styles.icon_list}>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={parking} alt = 'иконка'/>
                                <p className={styles.icon_text}>бесплатная парковка</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={bed} alt = 'иконка'/>
                                <p className={styles.icon_text}>двуспальная кровать</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={bed_1} alt = 'иконка'/>
                                <p className={styles.icon_text}>односпальная кровать</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={table} alt = 'иконка'/>
                                <p className={styles.icon_text}>стол и стулья</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={wc} alt = 'иконка'/>
                                <p className={styles.icon_text}>туалет</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={shower} alt = 'иконка'/>
                                <p className={styles.icon_text}>душ</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={conditioner} alt = 'иконка'/>
                                <p className={styles.icon_text}>кондиционер</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={television} alt = 'иконка'/>
                                <p className={styles.icon_text}>телевизор</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={refrigerator} alt = 'иконка'/>
                                <p className={styles.icon_text}>холодильник</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={dresser} alt = 'иконка'/>
                                <p className={styles.icon_text}>комод</p>
                            </li>
                        </ul>
                        <div className={styles.room_line}></div>
                        <p className={styles.room_text_bold}>расчётное время выезда 12:00 расчетное&#160;время&#160;заезда&#160;14:00</p>
                        <p className={styles.room_text}>По желанию наших гостей, номера дополнительно могут быть оборудованы спальными местами(300₽) и детской кроваткой(300₽).</p>
                        <p className={styles.room_text}>В целях пожарной безопасности, <span>категорически запрещается</span> использовать в номерах бытовые электроприборы (мультиварки, чайники, утюги и т.п.)!</p>
                    </div>
                </div>
                <div className={`${isImage === 3 ? styles.room : styles.room_none}`}>
                    <ul className={styles.list_image} onClick={fourClick}>
                        <li className={styles.item_1}>
                            <img className={styles.image} src={img_3} alt = 'здание'/>
                        </li>
                        <li className={styles.item_2}>
                            <img className={styles.image} src={img_3_1} alt = 'здание'/>
                        </li>
                        <li className={styles.item_3}>
                            <img className={styles.image} src={img_3_2} alt = 'здание'/>
                        </li>
                        <li className={styles.item_4}>
                            <img className={styles.image} src={img_3_3} alt = 'здание'/>
                        </li>
                    </ul>
                    <div className={styles.room_info}>
                        <h3 className={styles.room_title}>4-МЕСТНЫЙ НОМЕР (2&#160;+&#160;2)</h3>
                        <div className={styles.room_line}></div>
                        <div className={styles.box_price}>
                            <img className={styles.image_price} src={price} alt = 'деньги'/>
                            <p className={styles.room_price}>весна-осень от 2300₽ / лето&#160;от&#160;3200₽</p>
                        </div>
                        <div className={styles.box_button}>
                            <button className={styles.button} tupe='button' onClick={fourClick}>смотреть фото</button>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79166554701' aria-label='связаться вацап'>забронировать</a>
                        </div>
                        <div className={styles.room_line}></div>
                        <ul className={styles.icon_list}>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={parking} alt = 'иконка'/>
                                <p className={styles.icon_text}>бесплатная парковка</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={bed} alt = 'иконка'/>
                                <p className={styles.icon_text}>двуспальная кровать</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={bed_1} alt = 'иконка'/>
                                <p className={styles.icon_text}>односпальные кровати</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={table} alt = 'иконка'/>
                                <p className={styles.icon_text}>столы и стулья</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={wc} alt = 'иконка'/>
                                <p className={styles.icon_text}>туалет</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={shower} alt = 'иконка'/>
                                <p className={styles.icon_text}>душ</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={conditioner} alt = 'иконка'/>
                                <p className={styles.icon_text}>кондиционер</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={television} alt = 'иконка'/>
                                <p className={styles.icon_text}>телевизор</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={refrigerator} alt = 'иконка'/>
                                <p className={styles.icon_text}>холодильник</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={dresser} alt = 'иконка'/>
                                <p className={styles.icon_text}>два комода</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={cupboard} alt = 'иконка'/>
                                <p className={styles.icon_text}>шкаф</p>
                            </li>
                        </ul>
                        <div className={styles.room_line}></div>
                        <p className={styles.room_text_bold}>расчётное время выезда 12:00 расчетное&#160;время&#160;заезда&#160;14:00</p>
                        <p className={styles.room_text}>По желанию наших гостей, номера дополнительно могут быть оборудованы спальными местами(300₽) и детской кроваткой(300₽).</p>
                        <p className={styles.room_text}>В целях пожарной безопасности, <span>категорически запрещается</span> использовать в номерах бытовые электроприборы (мультиварки, чайники, утюги и т.п.)!</p>
                    </div>
                </div>
                <div className={`${isImage === 4 ? styles.room : styles.room_none}`}>
                    <ul 
                        className={styles.list_image}
                        variants={boxAnimation}>
                        <li className={styles.item_1} onClick={fourClick}>
                            <img className={styles.image} src={img_3} alt = 'здание'/>
                        </li>
                        <li className={styles.item_2}>
                            <img className={styles.image} src={img_3_2} alt = 'здание'/>
                        </li>
                        <li className={styles.item_3}>
                            <img className={styles.image} src={img_3_3} alt = 'здание'/>
                        </li>
                        <li className={styles.item_4}>
                            <img className={styles.image} src={img_3_1} alt = 'здание'/>
                        </li>
                    </ul>
                    <div className={styles.room_info}>
                        <h3 className={styles.room_title}>5-МЕСТНЫЙ НОМЕР (2&#160;+&#160;3)</h3>
                        <div className={styles.room_line}></div>
                        <div className={styles.box_price}>
                            <img className={styles.image_price} src={price} alt = 'деньги'/>
                            <p className={styles.room_price}>весна-осень от 2500₽ / лето&#160;от&#160;3500₽</p>
                        </div>
                        <div className={styles.box_button}>
                            <button className={styles.button} tupe='button' onClick={fourClick}>смотреть фото</button>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79166554701' aria-label='связаться вацап'>забронировать</a>
                        </div>
                        <div className={styles.room_line}></div>
                        <ul className={styles.icon_list}>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={parking} alt = 'иконка'/>
                                <p className={styles.icon_text}>бесплатная парковка</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={bed} alt = 'иконка'/>
                                <p className={styles.icon_text}>двуспальная кровать</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={bed_1} alt = 'иконка'/>
                                <p className={styles.icon_text}>односпальные кровати</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={table} alt = 'иконка'/>
                                <p className={styles.icon_text}>столы и стулья</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={wc} alt = 'иконка'/>
                                <p className={styles.icon_text}>туалет</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={shower} alt = 'иконка'/>
                                <p className={styles.icon_text}>душ</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={conditioner} alt = 'иконка'/>
                                <p className={styles.icon_text}>кондиционер</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={television} alt = 'иконка'/>
                                <p className={styles.icon_text}>телевизор</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={refrigerator} alt = 'иконка'/>
                                <p className={styles.icon_text}>холодильник</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={dresser} alt = 'иконка'/>
                                <p className={styles.icon_text}>два комода</p>
                            </li>
                            <li className={styles.icon_item}>
                                <img className={styles.icon_image} src={cupboard} alt = 'иконка'/>
                                <p className={styles.icon_text}>шкаф</p>
                            </li>
                        </ul>
                        <div className={styles.room_line}></div>
                        <p className={styles.room_text_bold}>расчётное время выезда 12:00 расчетное&#160;время&#160;заезда&#160;14:00</p>
                        <p className={styles.room_text}>По желанию наших гостей, номера дополнительно могут быть оборудованы спальными местами(300₽) и детской кроваткой(300₽).</p>
                        <p className={styles.room_text}>В целях пожарной безопасности, <span>категорически запрещается</span> использовать в номерах бытовые электроприборы (мультиварки, чайники, утюги и т.п.)!</p>
                    </div>
                </div>
            </div>
            </div>    
            
        </motion.section>
    )
}

export default Rooms