import React from 'react'
import styles from './Main.module.scss'
import InitialPage from '../../components/initialPage/InitialPage'
import AboutUs from '../../components/AboutUs/AboutUs'
import Practical from '../../components/Practical/Practical'
import Map from '../../components/Map/Map'
import ObjectsNearby from '../../components/ObjectsNearby/ObjectsNearby'
import Transfer from '../../components/Transfer/Transfer'
import InteriorDecoration from '../../components/InteriorDecoration/InteriorDecoration'
import Rooms from '../../components/Rooms/Rooms'
import Feedback from '../../components/Feedback/Feedback'

const Main = ({isImageOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage/>
            <AboutUs/>
            <Rooms
                isImageOpen={isImageOpen}
            />
            <Transfer/>
            <InteriorDecoration
                isImageOpen={isImageOpen}
            />
            <ObjectsNearby/>
            <Practical/>
            <Feedback/>
            <Map/>
        </section>
    )
}

export default Main