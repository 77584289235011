import React, {useCallback} from 'react'
import { motion } from "framer-motion"
import styles from './InteriorDecoration.module.scss'
import { arrTerritory } from '../../utils/data'
import { arrBeaches } from '../../utils/data'
import img_1 from '../../image/InteriorDecoration/Territory/img_1.webp'
import img_2 from '../../image/InteriorDecoration/Beaches/img_1.webp'


const titleAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const textAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .8}
    } 
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}


const InteriorDecoration = ({isImageOpen}) => {

    const territoryClick = useCallback(() => {
        isImageOpen(arrTerritory)
    }, [isImageOpen])


    const beachesClick = useCallback(() => {
        isImageOpen(arrBeaches)
    }, [isImageOpen])    


    return (
        <motion.section 
            className={styles.main}
            id='territory'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}><span>Пляжи, окресности</span> и&#160;наша&#160;территория</motion.h2>
                <motion.p className={styles.text} variants={textAnimation}>Территория гостевого дома хорошо благоустроена. Для досуга отдыхающих предусмотрена зона барбекю, батут, бесплатные велосипеды и самокаты, детские игрушки. Для транспортных средств наших гостей предоставляется бесплатная парковка. От наших ворот до спуска к морю 7-10 минут ходьбы.  На территории грязевого вулкана Тиздар, который находится рядом, построен большой комплекс для отдыхающих с кафе, грязевым бассейном, страусиной  фермой и аттракционами, для отдыхающих в нашем гостевом доме предоставляется скидка 50% для входа на его территорию.</motion.p>
                <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item} onClick={territoryClick}>
                        <img className={styles.item_image}  src={img_1} alt='Спальня'/>
                        <div className={styles.item_info}>
                            <h3 className={styles.item_title}>Наша территория</h3>
                        </div>
                    </li>
                    <li className={styles.item} onClick={beachesClick}>
                        <img className={styles.item_image}  src={img_2} alt='Спальня'/>
                        <div className={styles.item_info}>
                            <h3 className={styles.item_title}>Пляжи и окресности</h3>
                        </div>
                    </li>
                </motion.ul>
            </div>
        </motion.section>
    )
}

export default InteriorDecoration