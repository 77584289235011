import React from 'react'
import styles from './InitialPage.module.scss'


const InitialPage = () => {
    return (
        <section className={styles.main} id='initial'>
            <div className={styles.overlay}>
                <div className={styles.container}>
                    <p className={styles.text}>Хотите хорошо отдохнуть на Азовском море?</p>
                    <h1 className={styles.title}>добро пожаловать в&#160;гостевой&#160;дом на&#160;морской</h1>
                    <ul className={styles.list}>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Широкий выбор просторных и уютных номеров</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Благоустроенная территория  с зоной барбекю</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Всего в 7-10 минутах ходьбы от чистого голубого моря</p>
                        </li>
                    </ul>
                    <div className={styles.box_button}>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79166554701' aria-label='связаться вацап'>забронировать номер</a>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default InitialPage